import {
  Box,
  Center,
  Heading,
  Text,
  HStack,
  VStack,
  Stack,
  Avatar,
  useColorModeValue,
  Image,
} from "@chakra-ui/react";
// import background from "./images/mg-bckg-01-1280w.png";
// import background from "./images/mg-bckg-01-400w.png";
// import background from "./images/mg-bckg-01-600w.png";
// import background from "./images/mg-bckg-01-800w.png";
// import background from "./images/mg-bckg-02-1280w.png";
// import background from "./images/mg-bckg-02-btp-1280w.png";
// import background from "./images/mg-bckg-02-btp-1280x720.png";
// import background from "./images/mg-bckg-02-btp-1280x800.png";
// import background from "./images/mg-bckg-02-ptb-1280w.png";
// import background from "./images/mg-bckg-02-ptb-1280x720.png";
// import background from "./images/mg-bckg-02-ptb-1280x800.png";
// import background from "./images/mg-bckg-02r-1280w.png";
// import background from "./images/mg-bckg-03-1280w.png";
import background from "./images/mg-bckg-03-btp-1280w.png";
// import background from "./images/mg-bckg-03-btp-1280x720.png";
// import background from "./images/mg-bckg-03-ptb-1280w.png";
// import background from "./images/mg-bckg-03-ptb-1280x720.png";
// import background from "./images/mg-bckg-03r-1280w.png";
// import background from "./images/mg-bckg-04-1280w.png";
// import background from "./images/mg-bckg-04-btp-1280w.png";
// import background from "./images/mg-bckg-04-btp-1280x720.png";
// import background from "./images/mg-bckg-04-ptb-1280w.png";
// import background from "./images/mg-bckg-04-ptb-1280x720.png";
// import background from "./images/mg-bckg-04r-1280w.png";
// import background from "./images/mg-bckg-05-1280w.png";
// import background from "./images/mg-bckg-06-1280w.png";
// import background from "./images/mg-bckg-06-b1-1280w.png";
// import background from "./images/mg-bckg-06-b2-1280w.png";
// import background from "./images/mg-bckg-06c-1280w.png";
// import background from "./images/mg-bckg-06c-btp-1280w.png";
// import background from "./images/mg-bckg-06c-btp-1280x720.png";
// import background from "./images/mg-bckg-06cr-1280w.png";
// import background from "./images/mg-bckg-07-1280w.png";
// import background from "./images/mg-bckg-08-1280w.png";
// import background from "./images/mg-bckg-09-1280w.png";
// import background from "./images/mg-bckg-09-600w.png";
// import background from "./images/mg-bckg-09a-1280w.png";
// import background from "./images/mg-bckg-09b-1280w.png";
// import background from "./images/mg-bckg-09c-1280w.png";
// import background from "./images/mg-flag-01-200w.png";
// import background from "./images/mg-flag-01.svg";
// import background from "./images/mg-gear-logo-200w.png";
// import background from "./images/mg-gear-logo.svg";
// import background from "./images/mg-hero-01-200w.png";
// import background from "./images/mg-hero-02-200w.png";
// import background from "./images/mg-hero-03-200w.png";
// import background from "./images/mg-hero-04-1000sq.png";
// import background from "./images/mg-hero-04-1200w.png";
// import background from "./images/mg-hero-04-800sq.png";
// import background from "./images/mg-hero-05-200w.png";
// import background from "./images/mg-hero-05-300w.png";
// import background from "./images/mg-hero-05-800w.png";
// import background from "./images/mg-hero-06-1200w.png";
// import background from "./images/mg-hero-07-1200w.png";
// import background from "./images/mg-hero-08-1200w.png";
// import background from "./images/mg-hero-09-1200w.png";
// import background from "./images/mg-hero-10-1200w.png";
// import background from "./images/mg-hero-10-200sq.png";
// import background from "./images/mg-hero-10-200w.png";
// import background from "./images/mg-icon-01-200w.png";
// import background from "./images/mg-icon-02-200w.png";
// import background from "./images/mg-icon-03-200w.png";
// import background from "./images/mg-icon-04-200w.png";
// import background from "./images/mg-icon-04a-200w.png";
// import background from "./images/mg-icon-05-200w.png";
// import background from "./images/mg-icon-06-200w.png";
// import background from "./images/mg-logo-01-200w.png";
// import background from "./images/mg-logo-02-200w.png";
// import background from "./images/mg-profile-2022-200w.png";
// import background from "./images/mg-profile-players-200w.png";
// import background from "./images/mg-bckg-12-1280w.png";
// import background from "./images/mg-bckg-12-1280x360.png";
// import background from "./images/mg-bckg-12-1280x1440.png";

function CardTextPost() {
  return (
    <div style={{ backgroundImage: `url(${background})` }}>
      <Center align="top" py={6}>
        <VStack align="top">
          <HStack mr="2" ml="2">
            <Box
              maxW={"445px"}
              w={"full"}
              bg={useColorModeValue("gray.300", "gray.900")}
              boxShadow={"2xl"}
              rounded={"md"}
              p={6}
              overflow={"hidden"}
            >
              <Box
                h={"320px"}
                bg={"red.300"}
                mt={-6}
                mx={-6}
                mb={6}
                pos={"relative"}
              >
                <Image
                  src={
                    // " https://bafkreibazoeptlr4c5hrgdjzepthjm3iscwe56ojxcxrkorwufjqgfrpmu.ipfs.infura-ipfs.io/ "
                    // " https://bafybeih6j2bkbtr3p5ugxb6xvigqqziionmnh6sly2pwrqzbi6zgv3dwki.ipfs.infura-ipfs.io/ "
                    // " https://bafybeidg2zafwojw52otuvrbyp4w34rv6gwbijl2fnjlzamu3edqg7thy4.ipfs.infura-ipfs.io/ "
                    // " https://bafybeiexrfhhdlx3ric5v7yjez6pfexp4vhpszoq4l5ywdsg4mav4ifjcq.ipfs.infura-ipfs.io/ "
                    // " https://bafkreibazoeptlr4c5hrgdjzepthjm3iscwe56ojxcxrkorwufjqgfrpmu.ipfs.infura-ipfs.io"
                    // " https://bafkreibazoeptlr4c5hrgdjzepthjm3iscwe56ojxcxrkorwufjqgfrpmu.ipfs.infura-ipfs.io"
                    // " https://bafkreibazoeptlr4c5hrgdjzepthjm3iscwe56ojxcxrkorwufjqgfrpmu.ipfs.infura-ipfs.io"
                    // " https://bafybeigemdjp2j24wyqljbwc7nisg5l2m4h3r5ob5sa4eq4quml4egl3ae.ipfs.infura-ipfs.io/ "
                    // " https://bafybeih6j2bkbtr3p5ugxb6xvigqqziionmnh6sly2pwrqzbi6zgv3dwki.ipfs.infura-ipfs.io/ "
                    // " https://bafybeidg2zafwojw52otuvrbyp4w34rv6gwbijl2fnjlzamu3edqg7thy4.ipfs.infura-ipfs.io/ "
                    // " https://bafybeiexrfhhdlx3ric5v7yjez6pfexp4vhpszoq4l5ywdsg4mav4ifjcq.ipfs.infura-ipfs.io/ "
                    " https://bafybeieifivymmpggeju24nw2si7uumcxzarrjjx6l25dc4eaaupuwslzu.ipfs.infura-ipfs.io/"
                    // " https://bafybeigtzrprfalmfefukvqlwholo4mmjerqavicy6v23z5coyq2wneskm.ipfs.infura-ipfs.io/"
                    // " https://bafybeibr57ioe2q33kiqko4od2ecih624vxzjn75bmi7hcxpetdvff276e.ipfs.infura-ipfs.io/"
                    // " https://bafybeih2yx3lez53gt3iswcavitk5qdjvz542dnohtf6pbouwrxwhq5dme.ipfs.infura-ipfs.io/"
                    // " https://bafybeigycp4qkbhpgk2zv3gvrnmweajn57e6lp4eknvb4ehjiukguxisci.ipfs.infura-ipfs.io/"
                    // " https://bafybeiheu5fgk7jcfn6k4lywo2prjzo3fajvswvxjtce6nc65a4f3asqta.ipfs.infura-ipfs.io/"
                    // " https://bafybeibtoxoa5un4zs5cfvhutz4axcdv5alolrjau6asyltmczro7wej2y.ipfs.infura-ipfs.io/"
                    // " https://bafybeiewni54ty7y56jgak2ke7vdnuioibwtqer5uu5bboxcwp7xnyh4wa.ipfs.infura-ipfs.io/"
                    // " https://bafybeigaxfpv47tsk7h47nlqbwzxajjfhc4xkxk2tjz3aobvhe5xgjrwve.ipfs.infura-ipfs.io/"
                    // " https://bafybeie56rvgxfi6fs7yxqckqtye66fbprf7sgf3uo4rrsirwq2iobaumi.ipfs.infura-ipfs.io/"
                    // " https://bafybeicwlscnwijdnuc4j67bsrdnwigozqbvc5tmhlr23tol67sm3xdade.ipfs.infura-ipfs.io/"
                    // " https://bafybeigpvim4tjkw6mwaddpymft46grbswwzf3tkmskkznkfdus3udtgum.ipfs.infura-ipfs.io/"
                    // " https://bafybeia2thakmxvj2fpiaaloz733otuiewttonur4opufycnltcqnc4m3m.ipfs.infura-ipfs.io/"
                  }
                  alt=" MetaCollab "
                  layout={"fill"}
                />
              </Box>
              <Stack>
                <Text
                  color={"cyan"}
                  textTransform={"uppercase"}
                  fontWeight={800}
                  fontSize={"sm"}
                  letterSpacing={1.1}
                >
                  MetaCollab Tip 1
                </Text>
                <Heading
                  color={useColorModeValue("gray.700", "white")}
                  fontSize={"2xl"}
                  fontFamily={"body"}
                >
                  Boost Conversion Rates
                </Heading>
                <Text color={"gray.500"}>
                  MetaCollab provides value and service supporting the fourth
                  industrial revolution. Web3 is a set of technologies that
                  publicly interoperate through blockchain and node based
                  computing systems.
                </Text>
              </Stack>
              <Stack mt={6} direction={"row"} spacing={4} align={"center"}>
                <Avatar
                  src={
                    // " https://bafkreibazoeptlr4c5hrgdjzepthjm3iscwe56ojxcxrkorwufjqgfrpmu.ipfs.infura-ipfs.io/ "
                    // " https://bafybeih6j2bkbtr3p5ugxb6xvigqqziionmnh6sly2pwrqzbi6zgv3dwki.ipfs.infura-ipfs.io/ "
                    // " https://bafybeidg2zafwojw52otuvrbyp4w34rv6gwbijl2fnjlzamu3edqg7thy4.ipfs.infura-ipfs.io/ "
                    " https://bafybeiexrfhhdlx3ric5v7yjez6pfexp4vhpszoq4l5ywdsg4mav4ifjcq.ipfs.infura-ipfs.io/ "
                  }
                  alt={" MetaCollab "}
                />
                <Stack direction={"column"} spacing={0} fontSize={"sm"}>
                  <Text color={"gray.200"} fontWeight={600}>
                    {" "}
                    Scott Stevenson{" "}
                  </Text>
                  <Text color={"gray.500"}>
                    {" "}
                    September 1, 2021 · Short Read{" "}
                  </Text>
                </Stack>
              </Stack>
            </Box>

            <Box
              maxW={"445px"}
              w={"full"}
              bg={useColorModeValue("gray.300", "gray.900")}
              boxShadow={"2xl"}
              rounded={"md"}
              p={6}
              overflow={"hidden"}
            >
              <Box
                h={"320px"}
                bg={"red.500"}
                mt={-6}
                mx={-6}
                mb={6}
                pos={"relative"}
              >
                <Image
                  src={
                    // " https://bafkreibazoeptlr4c5hrgdjzepthjm3iscwe56ojxcxrkorwufjqgfrpmu.ipfs.infura-ipfs.io/ "
                    // " https://bafybeih6j2bkbtr3p5ugxb6xvigqqziionmnh6sly2pwrqzbi6zgv3dwki.ipfs.infura-ipfs.io/ "
                    // " https://bafybeidg2zafwojw52otuvrbyp4w34rv6gwbijl2fnjlzamu3edqg7thy4.ipfs.infura-ipfs.io/ "
                    // " https://bafybeiexrfhhdlx3ric5v7yjez6pfexp4vhpszoq4l5ywdsg4mav4ifjcq.ipfs.infura-ipfs.io/ "
                    // " https://bafkreibazoeptlr4c5hrgdjzepthjm3iscwe56ojxcxrkorwufjqgfrpmu.ipfs.infura-ipfs.io"
                    // " https://bafkreibazoeptlr4c5hrgdjzepthjm3iscwe56ojxcxrkorwufjqgfrpmu.ipfs.infura-ipfs.io"
                    // " https://bafkreibazoeptlr4c5hrgdjzepthjm3iscwe56ojxcxrkorwufjqgfrpmu.ipfs.infura-ipfs.io"
                    // " https://bafybeigemdjp2j24wyqljbwc7nisg5l2m4h3r5ob5sa4eq4quml4egl3ae.ipfs.infura-ipfs.io/ "
                    // " https://bafybeih6j2bkbtr3p5ugxb6xvigqqziionmnh6sly2pwrqzbi6zgv3dwki.ipfs.infura-ipfs.io/ "
                    // " https://bafybeidg2zafwojw52otuvrbyp4w34rv6gwbijl2fnjlzamu3edqg7thy4.ipfs.infura-ipfs.io/ "
                    // " https://bafybeiexrfhhdlx3ric5v7yjez6pfexp4vhpszoq4l5ywdsg4mav4ifjcq.ipfs.infura-ipfs.io/ "
                    " https://bafybeieifivymmpggeju24nw2si7uumcxzarrjjx6l25dc4eaaupuwslzu.ipfs.infura-ipfs.io/"
                    // " https://bafybeigtzrprfalmfefukvqlwholo4mmjerqavicy6v23z5coyq2wneskm.ipfs.infura-ipfs.io/"
                    // " https://bafybeibr57ioe2q33kiqko4od2ecih624vxzjn75bmi7hcxpetdvff276e.ipfs.infura-ipfs.io/"
                    // " https://bafybeih2yx3lez53gt3iswcavitk5qdjvz542dnohtf6pbouwrxwhq5dme.ipfs.infura-ipfs.io/"
                    // " https://bafybeigycp4qkbhpgk2zv3gvrnmweajn57e6lp4eknvb4ehjiukguxisci.ipfs.infura-ipfs.io/"
                    // " https://bafybeiheu5fgk7jcfn6k4lywo2prjzo3fajvswvxjtce6nc65a4f3asqta.ipfs.infura-ipfs.io/"
                    // " https://bafybeibtoxoa5un4zs5cfvhutz4axcdv5alolrjau6asyltmczro7wej2y.ipfs.infura-ipfs.io/"
                    // " https://bafybeiewni54ty7y56jgak2ke7vdnuioibwtqer5uu5bboxcwp7xnyh4wa.ipfs.infura-ipfs.io/"
                    // " https://bafybeigaxfpv47tsk7h47nlqbwzxajjfhc4xkxk2tjz3aobvhe5xgjrwve.ipfs.infura-ipfs.io/"
                    // " https://bafybeie56rvgxfi6fs7yxqckqtye66fbprf7sgf3uo4rrsirwq2iobaumi.ipfs.infura-ipfs.io/"
                    // " https://bafybeicwlscnwijdnuc4j67bsrdnwigozqbvc5tmhlr23tol67sm3xdade.ipfs.infura-ipfs.io/"
                    // " https://bafybeigpvim4tjkw6mwaddpymft46grbswwzf3tkmskkznkfdus3udtgum.ipfs.infura-ipfs.io/"
                    // " https://bafybeia2thakmxvj2fpiaaloz733otuiewttonur4opufycnltcqnc4m3m.ipfs.infura-ipfs.io/"
                  }
                  alt=" MetaCollab "
                  layout={"fill"}
                />
              </Box>
              <Stack>
                <Text
                  color={"cyan"}
                  textTransform={"uppercase"}
                  fontWeight={800}
                  fontSize={"sm"}
                  letterSpacing={1.1}
                >
                  MetaCollab Tip 2
                </Text>
                <Heading
                  color={useColorModeValue("gray.700", "white")}
                  fontSize={"2xl"}
                  fontFamily={"body"}
                >
                  Engage Players with Rewards
                </Heading>
                <Text color={"gray.500"}>
                  MetaCollab provides value and service supporting the fourth
                  industrial revolution. Web3 is a set of technologies that
                  publicly interoperate through blockchain and node based
                  computing systems.
                </Text>
              </Stack>
              <Stack mt={6} direction={"row"} spacing={4} align={"center"}>
                <Avatar
                  src={
                    // " https://bafkreibazoeptlr4c5hrgdjzepthjm3iscwe56ojxcxrkorwufjqgfrpmu.ipfs.infura-ipfs.io/ "
                    // " https://bafybeih6j2bkbtr3p5ugxb6xvigqqziionmnh6sly2pwrqzbi6zgv3dwki.ipfs.infura-ipfs.io/ "
                    " https://bafybeidg2zafwojw52otuvrbyp4w34rv6gwbijl2fnjlzamu3edqg7thy4.ipfs.infura-ipfs.io/ "
                    // " https://bafybeiexrfhhdlx3ric5v7yjez6pfexp4vhpszoq4l5ywdsg4mav4ifjcq.ipfs.infura-ipfs.io/ "
                  }
                  alt={" MetaCollab "}
                />
                <Stack direction={"column"} spacing={0} fontSize={"sm"}>
                  <Text color={"gray.200"} fontWeight={600}>
                    {" "}
                    Scott Stevenson{" "}
                  </Text>
                  <Text color={"gray.500"}>
                    {" "}
                    September 1, 2021 · Short Read{" "}
                  </Text>
                </Stack>
              </Stack>
            </Box>

            <Box
              maxW={"445px"}
              w={"full"}
              bg={useColorModeValue("gray.300", "gray.900")}
              boxShadow={"2xl"}
              rounded={"md"}
              p={6}
              overflow={"hidden"}
            >
              <Box
                h={"320px"}
                bg={"red.700"}
                mt={-6}
                mx={-6}
                mb={6}
                pos={"relative"}
              >
                <Image
                  src={
                    // " https://bafkreibazoeptlr4c5hrgdjzepthjm3iscwe56ojxcxrkorwufjqgfrpmu.ipfs.infura-ipfs.io/ "
                    // " https://bafybeih6j2bkbtr3p5ugxb6xvigqqziionmnh6sly2pwrqzbi6zgv3dwki.ipfs.infura-ipfs.io/ "
                    // " https://bafybeidg2zafwojw52otuvrbyp4w34rv6gwbijl2fnjlzamu3edqg7thy4.ipfs.infura-ipfs.io/ "
                    // " https://bafybeiexrfhhdlx3ric5v7yjez6pfexp4vhpszoq4l5ywdsg4mav4ifjcq.ipfs.infura-ipfs.io/ "
                    // " https://bafkreibazoeptlr4c5hrgdjzepthjm3iscwe56ojxcxrkorwufjqgfrpmu.ipfs.infura-ipfs.io"
                    // " https://bafkreibazoeptlr4c5hrgdjzepthjm3iscwe56ojxcxrkorwufjqgfrpmu.ipfs.infura-ipfs.io"
                    // " https://bafkreibazoeptlr4c5hrgdjzepthjm3iscwe56ojxcxrkorwufjqgfrpmu.ipfs.infura-ipfs.io"
                    // " https://bafybeigemdjp2j24wyqljbwc7nisg5l2m4h3r5ob5sa4eq4quml4egl3ae.ipfs.infura-ipfs.io/ "
                    // " https://bafybeih6j2bkbtr3p5ugxb6xvigqqziionmnh6sly2pwrqzbi6zgv3dwki.ipfs.infura-ipfs.io/ "
                    // " https://bafybeidg2zafwojw52otuvrbyp4w34rv6gwbijl2fnjlzamu3edqg7thy4.ipfs.infura-ipfs.io/ "
                    // " https://bafybeiexrfhhdlx3ric5v7yjez6pfexp4vhpszoq4l5ywdsg4mav4ifjcq.ipfs.infura-ipfs.io/ "
                    " https://bafybeieifivymmpggeju24nw2si7uumcxzarrjjx6l25dc4eaaupuwslzu.ipfs.infura-ipfs.io/"
                    // " https://bafybeigtzrprfalmfefukvqlwholo4mmjerqavicy6v23z5coyq2wneskm.ipfs.infura-ipfs.io/"
                    // " https://bafybeibr57ioe2q33kiqko4od2ecih624vxzjn75bmi7hcxpetdvff276e.ipfs.infura-ipfs.io/"
                    // " https://bafybeih2yx3lez53gt3iswcavitk5qdjvz542dnohtf6pbouwrxwhq5dme.ipfs.infura-ipfs.io/"
                    // " https://bafybeigycp4qkbhpgk2zv3gvrnmweajn57e6lp4eknvb4ehjiukguxisci.ipfs.infura-ipfs.io/"
                    // " https://bafybeiheu5fgk7jcfn6k4lywo2prjzo3fajvswvxjtce6nc65a4f3asqta.ipfs.infura-ipfs.io/"
                    // " https://bafybeibtoxoa5un4zs5cfvhutz4axcdv5alolrjau6asyltmczro7wej2y.ipfs.infura-ipfs.io/"
                    // " https://bafybeiewni54ty7y56jgak2ke7vdnuioibwtqer5uu5bboxcwp7xnyh4wa.ipfs.infura-ipfs.io/"
                    // " https://bafybeigaxfpv47tsk7h47nlqbwzxajjfhc4xkxk2tjz3aobvhe5xgjrwve.ipfs.infura-ipfs.io/"
                    // " https://bafybeie56rvgxfi6fs7yxqckqtye66fbprf7sgf3uo4rrsirwq2iobaumi.ipfs.infura-ipfs.io/"
                    // " https://bafybeicwlscnwijdnuc4j67bsrdnwigozqbvc5tmhlr23tol67sm3xdade.ipfs.infura-ipfs.io/"
                    // " https://bafybeigpvim4tjkw6mwaddpymft46grbswwzf3tkmskkznkfdus3udtgum.ipfs.infura-ipfs.io/"
                    // " https://bafybeia2thakmxvj2fpiaaloz733otuiewttonur4opufycnltcqnc4m3m.ipfs.infura-ipfs.io/"
                  }
                  alt=" MetaCollab "
                  layout={"fill"}
                />
              </Box>
              <Stack>
                <Text
                  color={"cyan"}
                  textTransform={"uppercase"}
                  fontWeight={800}
                  fontSize={"sm"}
                  letterSpacing={1.1}
                >
                  MetaCollab Article 3
                </Text>
                <Heading
                  color={useColorModeValue("gray.700", "white")}
                  fontSize={"2xl"}
                  fontFamily={"body"}
                >
                  Onboard Players Quickly
                </Heading>
                <Text color={"gray.500"}>
                  MetaCollab provides value and service supporting the fourth
                  industrial revolution. Web3 is a set of technologies that
                  publicly interoperate through blockchain and node based
                  computing systems.
                </Text>
              </Stack>
              <Stack mt={6} direction={"row"} spacing={4} align={"center"}>
                <Avatar
                  src={
                    // " https://bafkreibazoeptlr4c5hrgdjzepthjm3iscwe56ojxcxrkorwufjqgfrpmu.ipfs.infura-ipfs.io/ "
                    " https://bafybeih6j2bkbtr3p5ugxb6xvigqqziionmnh6sly2pwrqzbi6zgv3dwki.ipfs.infura-ipfs.io/ "
                    // " https://bafybeidg2zafwojw52otuvrbyp4w34rv6gwbijl2fnjlzamu3edqg7thy4.ipfs.infura-ipfs.io/ "
                    // " https://bafybeiexrfhhdlx3ric5v7yjez6pfexp4vhpszoq4l5ywdsg4mav4ifjcq.ipfs.infura-ipfs.io/ "
                  }
                  alt={" MetaCollab "}
                />
                <Stack direction={"column"} spacing={0} fontSize={"sm"}>
                  <Text color={"gray.200"} fontWeight={600}>
                    {" "}
                    Scott Stevenson{" "}
                  </Text>
                  <Text color={"gray.500"}>
                    {" "}
                    September 1, 2021 · Short Read{" "}
                  </Text>
                </Stack>
              </Stack>
            </Box>

            <Box
              maxW={"445px"}
              w={"full"}
              bg={useColorModeValue("gray.300", "gray.900")}
              boxShadow={"2xl"}
              rounded={"md"}
              p={6}
              overflow={"hidden"}
            >
              <Box
                h={"320px"}
                bg={"purple.900"}
                mt={-6}
                mx={-6}
                mb={6}
                pos={"relative"}
              >
                <Image
                  src={
                    // " https://bafkreibazoeptlr4c5hrgdjzepthjm3iscwe56ojxcxrkorwufjqgfrpmu.ipfs.infura-ipfs.io/ "
                    // " https://bafybeih6j2bkbtr3p5ugxb6xvigqqziionmnh6sly2pwrqzbi6zgv3dwki.ipfs.infura-ipfs.io/ "
                    // " https://bafybeidg2zafwojw52otuvrbyp4w34rv6gwbijl2fnjlzamu3edqg7thy4.ipfs.infura-ipfs.io/ "
                    // " https://bafybeiexrfhhdlx3ric5v7yjez6pfexp4vhpszoq4l5ywdsg4mav4ifjcq.ipfs.infura-ipfs.io/ "
                    // " https://bafkreibazoeptlr4c5hrgdjzepthjm3iscwe56ojxcxrkorwufjqgfrpmu.ipfs.infura-ipfs.io"
                    // " https://bafkreibazoeptlr4c5hrgdjzepthjm3iscwe56ojxcxrkorwufjqgfrpmu.ipfs.infura-ipfs.io"
                    // " https://bafkreibazoeptlr4c5hrgdjzepthjm3iscwe56ojxcxrkorwufjqgfrpmu.ipfs.infura-ipfs.io"
                    // " https://bafybeigemdjp2j24wyqljbwc7nisg5l2m4h3r5ob5sa4eq4quml4egl3ae.ipfs.infura-ipfs.io/ "
                    // " https://bafybeih6j2bkbtr3p5ugxb6xvigqqziionmnh6sly2pwrqzbi6zgv3dwki.ipfs.infura-ipfs.io/ "
                    // " https://bafybeidg2zafwojw52otuvrbyp4w34rv6gwbijl2fnjlzamu3edqg7thy4.ipfs.infura-ipfs.io/ "
                    // " https://bafybeiexrfhhdlx3ric5v7yjez6pfexp4vhpszoq4l5ywdsg4mav4ifjcq.ipfs.infura-ipfs.io/ "
                    " https://bafybeieifivymmpggeju24nw2si7uumcxzarrjjx6l25dc4eaaupuwslzu.ipfs.infura-ipfs.io/"
                    // " https://bafybeigtzrprfalmfefukvqlwholo4mmjerqavicy6v23z5coyq2wneskm.ipfs.infura-ipfs.io/"
                    // " https://bafybeibr57ioe2q33kiqko4od2ecih624vxzjn75bmi7hcxpetdvff276e.ipfs.infura-ipfs.io/"
                    // " https://bafybeih2yx3lez53gt3iswcavitk5qdjvz542dnohtf6pbouwrxwhq5dme.ipfs.infura-ipfs.io/"
                    // " https://bafybeigycp4qkbhpgk2zv3gvrnmweajn57e6lp4eknvb4ehjiukguxisci.ipfs.infura-ipfs.io/"
                    // " https://bafybeiheu5fgk7jcfn6k4lywo2prjzo3fajvswvxjtce6nc65a4f3asqta.ipfs.infura-ipfs.io/"
                    // " https://bafybeibtoxoa5un4zs5cfvhutz4axcdv5alolrjau6asyltmczro7wej2y.ipfs.infura-ipfs.io/"
                    // " https://bafybeiewni54ty7y56jgak2ke7vdnuioibwtqer5uu5bboxcwp7xnyh4wa.ipfs.infura-ipfs.io/"
                    // " https://bafybeigaxfpv47tsk7h47nlqbwzxajjfhc4xkxk2tjz3aobvhe5xgjrwve.ipfs.infura-ipfs.io/"
                    // " https://bafybeie56rvgxfi6fs7yxqckqtye66fbprf7sgf3uo4rrsirwq2iobaumi.ipfs.infura-ipfs.io/"
                    // " https://bafybeicwlscnwijdnuc4j67bsrdnwigozqbvc5tmhlr23tol67sm3xdade.ipfs.infura-ipfs.io/"
                    // " https://bafybeigpvim4tjkw6mwaddpymft46grbswwzf3tkmskkznkfdus3udtgum.ipfs.infura-ipfs.io/"
                    // " https://bafybeia2thakmxvj2fpiaaloz733otuiewttonur4opufycnltcqnc4m3m.ipfs.infura-ipfs.io/"
                  }
                  alt=" MetaCollab "
                  layout={"fill"}
                />
              </Box>
              <Stack>
                <Text
                  color={"cyan"}
                  textTransform={"uppercase"}
                  fontWeight={800}
                  fontSize={"sm"}
                  letterSpacing={1.1}
                >
                  MetaCollab Article 4
                </Text>
                <Heading
                  color={useColorModeValue("gray.700", "white")}
                  fontSize={"2xl"}
                  fontFamily={"body"}
                >
                  Talent Retention Incentives
                </Heading>
                <Text color={"gray.500"}>
                  MetaCollab provides value and service supporting the fourth
                  industrial revolution. Web3 is a set of technologies that
                  publicly interoperate through blockchain and node based
                  computing systems.
                </Text>
              </Stack>
              <Stack mt={6} direction={"row"} spacing={4} align={"center"}>
                <Avatar
                  src={
                    // " https://bafkreibazoeptlr4c5hrgdjzepthjm3iscwe56ojxcxrkorwufjqgfrpmu.ipfs.infura-ipfs.io/ "
                    // " https://bafybeih6j2bkbtr3p5ugxb6xvigqqziionmnh6sly2pwrqzbi6zgv3dwki.ipfs.infura-ipfs.io/ "
                    // " https://bafybeidg2zafwojw52otuvrbyp4w34rv6gwbijl2fnjlzamu3edqg7thy4.ipfs.infura-ipfs.io/ "
                    " https://bafybeiexrfhhdlx3ric5v7yjez6pfexp4vhpszoq4l5ywdsg4mav4ifjcq.ipfs.infura-ipfs.io/ "
                  }
                  alt={" MetaCollab "}
                />
                <Stack direction={"column"} spacing={0} fontSize={"sm"}>
                  <Text color={"gray.200"} fontWeight={600}>
                    {" "}
                    Scott Stevenson{" "}
                  </Text>
                  <Text color={"gray.500"}>
                    {" "}
                    September 1, 2021 · Short Read{" "}
                  </Text>
                </Stack>
              </Stack>
            </Box>
          </HStack>
        </VStack>
      </Center>
    </div>
  );
}
export default CardTextPost;
